import React, { useState, useEffect } from "react";
import { Nav, Accordion } from "react-bootstrap";
import "./drawer.css";
import Circle from "../Circle";
import Title from "../Title";
import { ConcerningLevelLibrary } from "app/containers/WebReport/helperFunctions";

const Drawer = ({
  title,
  metaDataContent,
  issueContent,
  closeDrawer,
  activeSection,
  foundSubsection,
  foundSection,
}) => {
  // Initializing all top-level sections to be open
  const initialActiveKeys = issueContent.map((section, index) => {
    return section?.subsections?.length > 0
      ? `accordion-${section.name}`
      : null;
  });

  const [sectionActiveKeys, setSectionActiveKeys] = useState(initialActiveKeys);
  const [subsectionActiveKeys, setSubsectionActiveKeys] = useState([]);

  // Function to toggle an accordion section
  const toggleAccordion = (key, isSubsection = false) => {
    if (isSubsection) {
      setSubsectionActiveKeys((prev) =>
        prev.includes(key) ? prev.filter((k) => k !== key) : [...prev, key]
      );
    } else {
      setSectionActiveKeys((prev) =>
        prev.includes(key) ? prev.filter((k) => k !== key) : [...prev, key]
      );
    }
  };

  useEffect(() => {
    if (foundSection) {
      setSectionActiveKeys((prev) => {
        const sectionKey = `accordion-${foundSection?.name}`;
        return prev.includes(sectionKey) ? prev : [...prev, sectionKey];
      });
    }

    if (foundSubsection) {
      setSubsectionActiveKeys((prev) => {
        const subsectionKey = `accordion-${foundSubsection?.name}`;
        return prev.includes(subsectionKey) ? prev : [...prev, subsectionKey];
      });
    }
  }, [foundSection, foundSubsection]);

  /* UI components */
  const metaDataNavItems = Object.keys(metaDataContent).map((item, index) => {
    return (
      <Nav key={item} className="metadata-nav-item">
        <div
          id={`nav-item-${item.toLowerCase()}`}
          className={`mx-2 w-100 ${
            activeSection === item.toLowerCase() ? "nav-item-active" : ""
          }`}
        >
          <Nav.Link href={`#${item.toLowerCase()}`} onClick={closeDrawer}>
            <div className="d-flex justify-content-between w-100">{item}</div>
          </Nav.Link>
        </div>
        <hr className="w-100 m-2" />
      </Nav>
    );
  });

  const issueContentArray = issueContent.map((section, index) => {
    const hasSubsections =
      section.subsections && section.subsections.length > 0;

    const issueTypeRecord = { hazard: 0, recommendation: 0, maintenance: 0 };
    if (!hasSubsections && section?.issues?.length > 0) {
      section.issues.forEach((issue) => {
        switch (issue.concern_level) {
          case 0:
            issueTypeRecord.maintenance++;
            break;
          case 1:
            issueTypeRecord.recommendation++;
            break;
          case 2:
            issueTypeRecord.hazard++;
            break;
          default:
            break;
        }
      });
    }

    const totalIssues = Object.values(issueTypeRecord).reduce(
      (acc, curr) => acc + curr,
      0
    );

    const mostSeriousIssue =
      issueTypeRecord.hazard > 0
        ? "concern-hazard"
        : issueTypeRecord.recommendation > 0
        ? "concern-recommendation"
        : "concern-maintenance";

    return (
      <Accordion.Item eventKey={`accordion-${section.name}`} key={section.name}>
        <Accordion.Header
          onClick={() => toggleAccordion(`accordion-${section.name}`)}
        >
          <div className="d-flex justify-content-between w-100">
            <div>{section.name}</div>
            {!hasSubsections ? (
              <Circle type={mostSeriousIssue} text={totalIssues} />
            ) : null}
          </div>
        </Accordion.Header>
        {hasSubsections && (
          <Accordion.Body>
            {section.subsections.map((subsection) => {
              // show circle on subsection
              const issueTypeRecord = {
                hazard: 0,
                recommendation: 0,
                maintenance: 0,
              };
              if (subsection?.issues) {
                subsection.issues.forEach((issue) => {
                  const concernLevel = issue?.concern_level;
                  switch (concernLevel) {
                    case 0:
                      issueTypeRecord.maintenance++;
                      break;
                    case 1:
                      issueTypeRecord.recommendation++;
                      break;
                    case 2:
                      issueTypeRecord.hazard++;
                      break;
                    default:
                      break;
                  }
                });
              }
              const totalIssues = Object.values(issueTypeRecord).reduce(
                (acc, curr) => acc + curr,
                0
              );

              const mostSeriousIssue =
                issueTypeRecord.hazard > 0
                  ? "concern-hazard"
                  : issueTypeRecord.recommendation > 0
                  ? "concern-recommendation"
                  : "concern-maintenance";

              return (
                <Accordion
                  key={subsection.name}
                  className="flex-column subsection-accordion-container"
                  activeKey={subsectionActiveKeys}
                >
                  <Accordion.Item eventKey={`accordion-${subsection.name}`}>
                    <Accordion.Header
                      onClick={() =>
                        toggleAccordion(`accordion-${subsection.name}`, true)
                      }
                    >
                      <div className="d-flex justify-content-between w-100">
                        <div className="w-75">{subsection.name}</div>
                        <Circle type={mostSeriousIssue} text={totalIssues} />
                      </div>
                    </Accordion.Header>
                    <Accordion.Body>
                      {subsection.issues.map((issue) => {
                        return (
                          <div
                            key={issue.id}
                            id={`nav-item-${issue.id}`}
                            className={`ps-0 pe-3 ${
                              activeSection === String(issue.id)
                                ? "issue-nav-item-active"
                                : ""
                            }`}
                          >
                            <Nav.Link
                              href={`#${issue.id}`}
                              key={issue.name || issue.order}
                              onClick={closeDrawer}
                            >
                              <div className="d-flex justify-content-between w-100">
                                {issue.name || issue.order}
                                {ConcerningLevelLibrary[issue.concern_level]
                                  ?.colorLabel ? (
                                  <Circle
                                    type={
                                      ConcerningLevelLibrary[
                                        issue.concern_level
                                      ].colorLabel
                                    }
                                    text={null}
                                    smallTopMargin={false}
                                  />
                                ) : null}
                              </div>
                            </Nav.Link>
                          </div>
                        );
                      })}
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              );
            })}
          </Accordion.Body>
        )}
      </Accordion.Item>
    );
  });

  return (
    <div className="drawer">
      <div className="drawer-title-container d-none d-lg-block px-2">
        <Title text={title} />
      </div>
      <div className="drawer-content-container">
        {metaDataNavItems}
        <Accordion
          className="section-accordion-container"
          activeKey={sectionActiveKeys}
        >
          {issueContentArray}
        </Accordion>
      </div>
    </div>
  );
};

export default Drawer;
