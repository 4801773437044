export const formattedDate = (date) => {
  return date ? new Date(date).toISOString().split("T")[0] : "";
};

export const ConcerningLevelLibrary = {
  0: {
    name: "Maintenance Item",
    colorRule: "concerning-level-maintenance",
    colorLabel: "concern-maintenance",
  },
  1: {
    name: "Recommendation",
    colorRule: "concerning-level-recommendation",
    colorLabel: "concern-recommendation",
  },
  2: {
    name: "Safety Hazard",
    colorRule: "concerning-level-safety-hazard",
    colorLabel: "concern-hazard",
  },
};

export const calculateSticky = (
  isMobile = true,
  activeSectionEl,
  titleSelector,
  subtitleSelector = null
) => {
  const activeSectionRect = activeSectionEl.getBoundingClientRect();
  const childTitleEl = activeSectionEl.querySelector(titleSelector);
  const childTitleRect = childTitleEl?.getBoundingClientRect();

  const offset = isMobile ? 60 : 0;
  const topHeight = -34 + offset;
  const bottomHeight = 10 + offset;

  if (!subtitleSelector) {
    if (!childTitleRect) return false;
    return (
      activeSectionRect.top <= (0 + offset - childTitleRect.height) / 2 &&
      activeSectionRect.bottom >= bottomHeight
    );
  }

  const parentElement = activeSectionEl.closest(".individual-issue-wrapper");
  if (!parentElement) return false;
  const childSubtitleEl = parentElement.querySelector(subtitleSelector);
  const childSubtitleRect = childSubtitleEl?.getBoundingClientRect();

  return (
    childSubtitleRect?.top <= topHeight &&
    activeSectionRect?.bottom >= bottomHeight
  );
};
