import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Offcanvas, Button } from "react-bootstrap";
import { startCase, debounce } from "lodash";
import { useParams } from "react-router-dom";
import { fetchWebReport } from "./slice";
import LeftNavigation from "./leftNavigation";
import ReportContent from "./reportContent";
import "./webReport.css";
import burgerMenu from "../../components/Images/burger-menu.svg";

const WebReport = () => {
  // For mobile view
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [isMobile, setIsMobile] = useState(
    window.matchMedia("(max-width: 991px)").matches
  );

  useEffect(() => {
    const mediaQuery = window.matchMedia("(max-width: 991px)");

    // Initial check
    setIsMobile(mediaQuery.matches);

    // Handler function
    const handleScreenChange = (e) => {
      setIsMobile(e.matches);
    };

    // Add listener
    mediaQuery.addEventListener("change", handleScreenChange);

    // Cleanup
    return () => {
      mediaQuery.removeEventListener("change", handleScreenChange);
    };
  }, []);

  /* scroll-spy */
  const [activeSection, setActiveSection] = useState("project information");

  useEffect(() => {
    if (!activeSection) {
      setActiveSection("project information");
    }

    const handleScroll = debounce(() => {
      const sections = document.querySelectorAll(
        ".issue-container, .metadata-container"
      );
      let currentSection = "";

      const activeDimensions = {
        top: isMobile ? 104 : 44,
        bottom: isMobile ? 124 : 64,
      };

      sections.forEach((section) => {
        const rect = section.getBoundingClientRect();
        if (
          rect.top <= activeDimensions.top &&
          rect.bottom >= activeDimensions.bottom
        ) {
          currentSection = section.id;
        }
      });

      if (currentSection) {
        setActiveSection(currentSection);
      }
    }, 20);

    window.addEventListener("scroll", handleScroll);
    handleScroll(); // check on mount in case user is already scrolled

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const dispatch = useDispatch();

  const { webReportContent, requestStatus } = useSelector(
    (state) => state.webReport
  );

  const { reportId } = useParams();

  useEffect(() => {
    // Initialize component, fetch data, etc.
    dispatch(fetchWebReport(reportId));
  }, [dispatch, reportId]);

  const {
    address,
    apn,
    created_at,
    floor_area,
    house_type,
    id,
    info,
    name,
    sections,
    stories,
    updated_at,
    user,
    year_build,
    summary,
  } = webReportContent;

  // update the title of the page
  useEffect(() => {
    let displayTitle = webReportContent?.address?.formatted_address
      ? webReportContent?.address?.formatted_address +
        " - " +
        webReportContent?.name
      : "Web Report";

    document.title = displayTitle;
  }, [webReportContent]);

  // Find both the subsection and section names
  const [foundSection, setFoundSection] = useState(null);
  const [foundSubsection, setFoundSubsection] = useState(null);
  const [activeIssueInView, setActiveIssueInView] = useState(null);

  /* Scroll and Toggle open the active section */
  useEffect(() => {
    if (!activeSection) return;

    const activeIssueNavItem = document.getElementById(
      `nav-item-${activeSection}`
    );
    if (!activeIssueNavItem) return;

    activeIssueNavItem.scrollIntoView({ behavior: "smooth" });

    if (["project information", "overview"].includes(activeSection)) {
      const foundSectionToUse = { name: startCase(activeSection) };
      setFoundSection(foundSectionToUse);
      setFoundSubsection(null);
    } else {
      sections.forEach((section) => {
        section.subsections.forEach((subsection) => {
          const issue = subsection.issues.find(
            (issue) => String(issue.id) === String(activeSection)
          );
          if (issue) {
            setFoundSection(section);
            setFoundSubsection(subsection);
            setActiveIssueInView(issue);
          }
        });
      });
    }
  }, [activeSection, sections]);

  if (requestStatus !== "success") {
    return <div>Loading...</div>;
  }

  const metaDataItems = {
    "Project Information": {
      created_at,
      id,
      info,
      name,
      updated_at,
      user,
      summary,
      propertyDetails: [
        {
          key: "address",
          value: `${address.address_line1 || ""} ${
            address.address_line2 || ""
          }`,
        },
        {
          key: "city",
          value: address.locality || "",
        },
        {
          key: "state",
          value: address.administrative_area || "",
        },
        {
          key: "zipCode",
          value: address.postal_code || "",
        },
        {
          key: "year_build",
          value: year_build,
        },
        {
          key: "floor_area",
          value: floor_area,
        },
        {
          key: "stories",
          value: stories,
        },
        {
          key: "house_type",
          value: house_type,
        },
        {
          key: "apn",
          value: apn,
        },
        {
          key: "info",
          value: info,
        },
      ],
    },
    Overview: {},
  };
  const issueItems = sections || [];

  const navigationHeader =
    metaDataItems?.["Project Information"]?.propertyDetails?.find(
      (field) => field.key === "address"
    ).value +
      " - " +
      metaDataItems?.["Project Information"]?.name || "Web Report";

  return (
    <div className="web-report-container">
      <div className="button-container d-lg-none">
        <Button variant="primary" onClick={handleShow}>
          <div>
            <img src={burgerMenu} alt="burger menu" />
          </div>
          <div className="top-title-truncate">{navigationHeader}</div>
        </Button>
      </div>

      <Offcanvas
        show={show}
        onHide={handleClose}
        responsive="lg"
        scroll={true}
        className=""
      >
        <Offcanvas.Body>
          <LeftNavigation
            metaDataItems={metaDataItems}
            issueItems={issueItems}
            title={navigationHeader}
            closeDrawer={handleClose}
            activeSection={activeSection}
            foundSubsection={foundSubsection}
            foundSection={foundSection}
          />
        </Offcanvas.Body>
      </Offcanvas>
      <ReportContent
        metaDataItems={metaDataItems}
        issueItems={issueItems}
        activeSection={activeSection}
        foundSubsection={foundSubsection}
        foundSection={foundSection}
        activeIssueInView={activeIssueInView}
        isMobile={isMobile}
      />
    </div>
  );
};

WebReport.propTypes = {
  // Define prop types here
};

export default WebReport;
