import React from "react";

const colorMap = {
  "concern-hazard": "#972600",
  "concern-recommendation": "#FF8B6A",
  "concern-maintenance": "#476177",
};

const Circle = ({ type, text = null, smallTopMargin = true }) => {
  const color = colorMap[type];

  const circleStyles = {
    width: text ? "16px" : "8px", // Make circle bigger if there's text
    height: text ? "16px" : "8px",
    backgroundColor: color,
    borderRadius: "50% ",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "white", // Text color
    fontSize: "8px", // Adjust text size as needed
    fontWeight: "bold", // Optional: make text bold,
    marginTop: smallTopMargin ? "2px" : "10px",
  };

  return (
    <div className="circle" style={circleStyles}>
      {text}
    </div>
  );
};

export default Circle;
