import React from "react";
import Drawer from "../../components/Drawer";
import "./webReport.css";

const LeftNavigation = ({
  metaDataItems,
  issueItems,
  title,
  closeDrawer,
  activeSection,
  foundSubsection,
  foundSection,
}) => {
  const toggableLevels = ["sections", "subsections", "issues"];
  return (
    <Drawer
      title={title}
      metaDataContent={metaDataItems}
      issueContent={issueItems}
      toggableLevels={toggableLevels}
      closeDrawer={closeDrawer}
      activeSection={activeSection}
      foundSubsection={foundSubsection}
      foundSection={foundSection}
    />
  );
};

export default LeftNavigation;
