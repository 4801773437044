import React, { useState, useEffect } from "react";
import "./webReport.css";
import MetaDataSection from "./metaDataSection";
import IssueSection from "./issueSection";
import { calculateSticky } from "./helperFunctions";
import Item from "../../components/Item";
import ConcerningLevelBadge from "../../components/ConcerningLevelBadge";

const ReportContent = ({
  metaDataItems,
  issueItems,
  activeSection,
  foundSection,
  foundSubsection,
  activeIssueInView,
  isMobile,
}) => {
  // Add this state to track scroll position
  const [isSticky, setIsSticky] = useState(false);
  const [showIssueTitleInStickyHeader, setShowIssueTitleInStickyHeader] =
    useState(false);

  // Add scroll listener
  useEffect(() => {
    const handleScroll = () => {
      const activeSectionEl = document.getElementById(activeSection);

      if (!activeSectionEl) return;

      let showSticky = false;

      if (["project information", "overview"].includes(activeSection)) {
        const titleSelector =
          activeSection === "project information" ? ".title-wrapper" : ".item";
        showSticky = calculateSticky(isMobile, activeSectionEl, titleSelector);
      } else {
        showSticky = calculateSticky(
          isMobile,
          activeSectionEl,
          ".title-wrapper",
          ".subtitle"
        );

        const activeSectionTitleRect = activeSectionEl
          .querySelector(".item")
          ?.getBoundingClientRect();

        const passedHeight = isMobile ? 24 : -44;
        if (activeSectionTitleRect?.top <= passedHeight) {
          setShowIssueTitleInStickyHeader(true);
        } else {
          setShowIssueTitleInStickyHeader(false);
        }
      }

      setIsSticky(showSticky);
    };

    window.addEventListener("scroll", handleScroll);

    const handleBeforePrint = () => {
      setIsSticky(false);
    };

    window.addEventListener("beforeprint", handleBeforePrint);

    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("beforeprint", handleBeforePrint);
    };
  }, [activeSection]);

  return (
    <div>
      <div
        className={`sticky-header-wrapper header ${isSticky ? "sticky" : ""}`}
      >
        <div>
          {foundSection?.name}
          {foundSubsection ? " - " : ""}
          {foundSubsection?.name}
          {showIssueTitleInStickyHeader && (
            <Item
              text={activeIssueInView.name || activeIssueInView.id}
              children={null}
              showBadge
              badgeContent={
                <ConcerningLevelBadge
                  concerningLevel={activeIssueInView.concern_level}
                />
              }
              showDivider={false}
            />
          )}
        </div>
      </div>
      <div className="main-content">
        <div>
          <MetaDataSection metaDataItems={metaDataItems} />
          <IssueSection issueItems={issueItems} />
        </div>
      </div>
    </div>
  );
};

export default ReportContent;
