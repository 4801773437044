import React from "react";
import Circle from "./Circle";

const SubTitle = ({ text, totalIssues, issueTypeRecord, placedAt }) => {
  return (
    <div className="subtitle">
      <div className="subtitle-text">{text}</div>
      {placedAt === "issueSection" && (
        <div className="subtitle-circle-container d-flex align-items-center">
          {issueTypeRecord.hazard > 0 ? <Circle type="concern-hazard" /> : null}
          {issueTypeRecord.recommendation > 0 ? (
            <Circle type="concern-recommendation" />
          ) : null}
          {issueTypeRecord.maintenance > 0 ? (
            <Circle type="concern-maintenance" />
          ) : null}
          <span className="total-issues ms-2">
            {totalIssues} issue{totalIssues > 1 ? "s" : ""}
          </span>
        </div>
      )}
    </div>
  );
};

export default SubTitle;
